import * as React from 'react';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

import Layout from '../components/layout';
import Rule from '../components/rule';
import Section from '../components/section';

import { ReactComponent as LinkedInSvg } from '../images/svg/remixicon/linkedin.svg';

import { ReactComponent as ContactSvg } from '../images/svg/undraw/delivery-address.svg';

const title = 'Contact Us - How to contact David Simpson Apps';
const description =
  "We'd love to hear from you! There are a bunch of ways to reach us, so pick the one that works best for you, and let's start the conversation!";
const ContactPage = () => (
  <>
    <GatsbySeo
      title={title}
      description={description}
      language="en"
      openGraph={{
        title,
        description,
        url: 'https://www.dsapps.dev/contact/',
      }}
    />
    <Layout pageTitle={title}>
      <Section>
        <section className="text-gray-600 dark:text-gray-300 body-font">
          <div className="_container flex flex-col md:flex-row">
            <div className="flex flex-col mb-16 text-left md:w-2/3 lg:pr-24 md:pr-16 md:items-start md:mb-0">
              <Rule />
              <h1 className="mb-4 text-4xl text-gray-900 dark:text-gray-100 sm:text-4xl">
                Contact Us
              </h1>

              <p>
                <strong>Email:</strong>
                <br />
                <a
                  className="text-blue-500 animate-underline"
                  href="mailto:hello@dsapps.dev"
                >
                  hello@dsapps.dev
                </a>
              </p>

              <p>
                <strong>Phone:</strong>
                <br />
                <a
                  className="text-blue-500 animate-underline"
                  href="tel:+44 115 888 0998"
                >
                  +44 115 888 0998
                </a>
              </p>

              <p>
                <strong>David Simpson Apps</strong> is a trading name of
                <br />
                <strong>Concise Web Design Limited</strong>.
              </p>

              <p>
                <strong>Management:</strong>
                <br />
                David Simpson PhD FRSA
                <OutboundLink
                  href="https://www.linkedin.com/in/dvdsmpsn/"
                  target="_blank"
                  title="LinkedIn"
                  className="mr-3 opacity-80 hover:opacity-100"
                  rel="noreferrer"
                >
                  <LinkedInSvg />
                </OutboundLink>
              </p>
              <p>
                <strong>Registered office:</strong>
                <div className="postal-address">
                  Unit 8, Wheatcroft Business Park
                  <br />
                  Landmere Lane
                  <br />
                  Edwalton
                  <br />
                  Nottingham
                  <br />
                  NG12 4DG
                  <br />
                  United Kingdom
                </div>
              </p>

              <p>
                <strong>UK Company Number:</strong>
                <br />
                04944014
              </p>

              <p>
                <strong>VAT Registration Number:</strong>
                <br />
                828 2048 30
              </p>
            </div>

            <div className="items-center w-5/6 md:mt-10 md:w-1/3">
              <ContactSvg />
            </div>
          </div>
        </section>
      </Section>
    </Layout>
  </>
);

export default ContactPage;
